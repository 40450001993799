import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import GradientText from "../common/elements/gradients/gradientText"
import GradientUnderline from "../common/elements/gradients/gradientUnderline"
import GradientButton from "../common/elements/gradients/gradientButton"
import Fade from "react-reveal/Fade"
import { Container } from "../global"
import FullImage from "./full-image"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Grid from "../common/grid"
// import "../elements/gradients/gradient.css"
import "./local-styles.css"

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "green-skew" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const CustomForm = ({ status, message, onValidated }) => {
    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })

    return (
      <>
        <div>
          {status === "sending" && <div className="status">Sending...</div>}
          {status === "error" && (
            <div
              className="status"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="status"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          <br />
          <BrowserView>
            <div className="header-form">
              <input
                className="header-input"
                ref={(node) => (email = node)}
                type="email"
                placeholder="Your email"
              />
              <br />
              <button className="gradient-button" onClick={submit}>
                Get Updates
              </button>{" "}
            </div>
          </BrowserView>
          <MobileView>
            <input
              className="header-input"
              style={{}}
              ref={(node) => (email = node)}
              type="email"
              placeholder="Your email"
            />
            <button
              className="gradient-button"
              style={{ display: "block", width: "100%", margin: "10px 0" }}
              onClick={submit}
            >
              Get Updates
            </button>{" "}
          </MobileView>
        </div>
      </>
    )
  }

  const url =
    "https://hone.us17.list-manage.com/subscribe/post?u=bf9d34f61faf5b2ce618612c7&amp;id=7caaade29f"

  return (
    <>
      <BrowserView>
      <LayoutWrapper>
        <Grid>
          <FullImage />
          <HeaderWrapper id="top">
            
            <Container>
              <Flex>
                {/* <Fade cascade> */}
                <HeaderTextGroup>
                  <h1>
                    Take Back <br />
                    Your <GradientText>Time</GradientText>
                  </h1>
                  <Subtext>
                    Hone helps you manage your time in a more mindful and
                    productive way, do your best work, and replace your
                    distractions with focus.
                  </Subtext>

                  {/* <MailchimpSubscribe url={url}/> */}

                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <CustomForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />

                  <FormSubtitle></FormSubtitle>
                </HeaderTextGroup>

                <CenterHead>{/* <Typeform /> */}</CenterHead>
              </Flex>
            </Container>
          </HeaderWrapper>
        </Grid>
        <ThreeGrid>
          <GridItem>
            <ItemIcon><svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.141 7.03394L22.1487 4.44424C22.6837 4.09913 23.3097 4.72515 22.9646 5.26021L20.3749 9.26782C18.5129 12.1465 18.5129 15.8491 20.3749 18.7277L22.9646 22.7353C23.3097 23.2704 22.6837 23.8964 22.1487 23.5513L18.141 20.9616C15.2624 19.0996 11.5598 19.0996 8.68115 20.9616L4.67354 23.5513C4.13848 23.8964 3.51246 23.2704 3.85757 22.7353L6.44727 18.7277C8.30929 15.8491 8.30929 12.1465 6.44727 9.26782L3.85757 5.26021C3.51246 4.72515 4.13848 4.09913 4.67354 4.44424L8.68115 7.03394C11.5598 8.89596 15.2624 8.89596 18.141 7.03394Z" fill="#4865FF"/>
<path d="M26.1664 6.70028C25.7116 6.70028 25.3424 6.33109 25.3424 5.87629V4.11058C25.3424 2.94147 24.3927 1.99174 23.2236 1.99174L21.4231 1.99174C20.9683 1.99174 20.5991 1.62255 20.5991 1.16774C20.5991 0.712942 20.9683 0.34375 21.4231 0.34375L23.2236 0.34375C25.2996 0.34375 26.9904 2.03454 26.9904 4.11058V5.87629C26.9904 6.33109 26.6212 6.70028 26.1664 6.70028Z" fill="#4865FF"/>
<path d="M23.225 27.4201L21.4592 27.4201C21.0044 27.4201 20.6353 27.0509 20.6353 26.5961C20.6353 26.1413 21.0044 25.7721 21.4592 25.7721L23.225 25.7721C24.3941 25.7721 25.3438 24.8224 25.3438 23.6533V21.8528C25.3438 21.398 25.713 21.0288 26.1678 21.0288C26.6226 21.0288 26.9918 21.398 26.9918 21.8528V23.6533C26.9918 25.732 25.3037 27.4201 23.225 27.4201Z" fill="#4865FF"/>
<path d="M0.823995 6.70028C0.369192 6.70028 0 6.33109 0 5.87629V4.11058C0 2.03187 1.69079 0.34375 3.76683 0.34375L5.56731 0.34375C6.02212 0.34375 6.39131 0.712942 6.39131 1.16774C6.39131 1.62255 6.02212 1.99174 5.56731 1.99174L3.76683 1.99174C2.59772 1.99174 1.64799 2.94147 1.64799 4.11058V5.87629C1.64799 6.33109 1.2788 6.70028 0.823995 6.70028Z" fill="#4865FF"/>
<path d="M5.53254 27.4201L3.76683 27.4201C1.69079 27.4201 0 25.732 0 23.6533V21.8528C0 21.398 0.369192 21.0288 0.823995 21.0288C1.2788 21.0288 1.64799 21.398 1.64799 21.8528V23.6533C1.64799 24.8224 2.59772 25.7721 3.76683 25.7721L5.53254 25.7721C5.98734 25.7721 6.35653 26.1413 6.35653 26.5961C6.35653 27.0509 5.98734 27.4201 5.53254 27.4201Z" fill="#4865FF"/>
</svg>
</ItemIcon>
            <ItemHeader>Flexible to you</ItemHeader>
            <ItemDesc>Categorize your tasks, set up your own workflows, take notes for in-progress tasks etc etc. </ItemDesc>
         <GridImage>Plan</GridImage>
         </GridItem>
          <GridItem>
            <ItemIcon><svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2755 1.57587C18.3114 1.57587 18.4201 1.57587 18.493 1.70193C18.5659 1.828 18.511 1.92256 18.493 1.95407L13.9614 9.8334C13.3682 10.8656 13.0565 12.0396 13.0587 13.2316C13.0621 14.4102 13.3738 15.5729 13.9614 16.5939L18.5087 24.5002C18.5267 24.5317 18.5816 24.6263 18.5087 24.7523C18.4358 24.8784 18.3271 24.8784 18.2912 24.8784H1.8391C1.80322 24.8784 1.69444 24.8784 1.62155 24.7523C1.54866 24.6263 1.60361 24.5317 1.62155 24.5002L6.15313 16.6209C6.74635 15.5887 7.0581 14.4147 7.05586 13.2226C7.0525 12.0441 6.74075 10.8814 6.15313 9.86042L1.60585 1.95295C1.58791 1.92143 1.53296 1.82688 1.60585 1.70081C1.67874 1.57587 1.78752 1.57587 1.8234 1.57587H18.2755ZM18.2755 0H1.8234C0.421654 0 -0.454159 1.52296 0.246716 2.74088L4.79399 10.6472C5.25265 11.4453 5.48366 12.3357 5.4859 13.226C5.48814 14.1254 5.25713 15.0259 4.79399 15.8318L0.262415 23.7111C-0.43846 24.9291 0.437354 26.452 1.8391 26.452H18.2912C19.6929 26.452 20.5687 24.9291 19.8679 23.7111L15.3206 15.8048C14.8619 15.0067 14.6309 14.1164 14.6287 13.226C14.6264 12.3266 14.8574 11.4262 15.3206 10.6202L19.8522 2.74088C20.553 1.52296 19.6772 0 18.2755 0Z" fill="#27CBB4"/>
<path d="M8.15076 17.0086L5.59509 22.1065C5.37305 22.55 5.69378 23.0734 6.18943 23.0734H13.9641C14.4586 23.0734 14.7805 22.55 14.5584 22.1065L12.0028 17.0086C11.4006 15.8075 11.0866 14.4804 11.0866 13.1353V12.8708C11.0866 11.5977 11.4107 10.346 12.0286 9.23394L12.1553 9.00431C12.3796 8.60021 12.0891 8.10156 11.6271 8.10156H8.60269C8.14964 8.10156 7.85696 8.58333 8.06554 8.98742L8.23375 9.31385C8.78099 10.3753 9.06695 11.5527 9.06695 12.747V13.1342C9.06695 14.4804 8.75296 15.8064 8.15076 17.0086Z" fill="#27CBB4"/>
</svg>
</ItemIcon>
            <ItemHeader>Find your flow</ItemHeader>
            <ItemDesc>Hone is designed for you to focus on one task at a time, limit distractions, and hold yourself accountable.</ItemDesc>
            <GridImage>Enact</GridImage>
          </GridItem>
          <GridItem>
            <ItemIcon><svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6332 1.92519C22.3404 1.92519 23.7319 3.31408 23.7319 5.02389V22.6636C23.7319 24.3709 22.343 25.7623 20.6332 25.7623H4.68013C2.9729 25.7623 1.58144 24.3734 1.58144 22.6636V5.02389C1.58144 3.31665 2.97033 1.92519 4.68013 1.92519H20.6332ZM20.6332 0.34375H4.68013C2.09489 0.34375 0 2.43864 0 5.02389V22.6636C0 25.2489 2.09489 27.3438 4.68013 27.3438H20.6332C23.2184 27.3438 25.3133 25.2489 25.3133 22.6636V5.02389C25.3107 2.43864 23.2158 0.34375 20.6332 0.34375Z" fill="#C666F3"/>
<path d="M16.8932 20.85L14.6174 14.8051C14.4385 14.328 14.5966 13.7862 15.0059 13.4935C16.1471 12.678 16.8702 11.2983 16.7835 9.75217C16.6617 7.56144 14.883 5.80304 12.7289 5.73825C10.3576 5.66675 8.41206 7.60277 8.41206 10.0013C8.41206 11.4246 9.09789 12.6847 10.1502 13.4589C10.5606 13.7616 10.7329 14.2979 10.5518 14.7805L8.26721 20.85C8.07518 21.3616 8.44498 21.9101 8.98267 21.9101H16.1767C16.7144 21.9101 17.0853 21.3616 16.8932 20.85Z" fill="#C666F3"/>
</svg>
</ItemIcon>
            <ItemHeader>Safe and secure</ItemHeader>
            <ItemDesc>Hone's app data is stored entirely on your device and works offline.</ItemDesc>
          <GridImage>Report</GridImage>
          </GridItem>
        </ThreeGrid>
        <div style={{ borderRadius:'12px', padding:'3rem'}}>
        <h1 style={{margin: '0 auto 3rem auto', textAlign:'center'}}>Start Single-Tasking</h1>
                        <a  id="download" style={{textDecoration: 'none', color: 'white'}} href="https://apps.apple.com/us/app/hone-one-thing-at-a-time/id1624789090?mt=12">
          <button style={{margin: '0 auto 5rem auto'}} className="gradient-button big">
Download for Mac
          </button></a>
          </div>
        </LayoutWrapper>
      </BrowserView>
      <MobileView>
        <Flex>
          {/* <Fade cascade> */}
          <h1
            style={{
              textAlign: "center",
              fontSize: "40px",
              margin: "0",
              color: "#292B32",
              paddingTop: ".75rem",
              paddingBottom: '1rem'
            }}
          >
            Take Back <br />
            Your <GradientText>Time</GradientText>
          </h1>
          <FullImage />
          <HeaderTextGroup>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />

            <Subtext
              style={{
                textAlign: "center",
                fontSize: "20px",
                paddingTop: "3rem",
              }}
            >
              Hone helps you manage your time in a more mindful and productive
              way. Do your best work and replace your distractions with focus.
            </Subtext>
          </HeaderTextGroup>
           <ThreeGridMobile>
          <GridItem>
            <ItemIcon><svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.141 7.03394L22.1487 4.44424C22.6837 4.09913 23.3097 4.72515 22.9646 5.26021L20.3749 9.26782C18.5129 12.1465 18.5129 15.8491 20.3749 18.7277L22.9646 22.7353C23.3097 23.2704 22.6837 23.8964 22.1487 23.5513L18.141 20.9616C15.2624 19.0996 11.5598 19.0996 8.68115 20.9616L4.67354 23.5513C4.13848 23.8964 3.51246 23.2704 3.85757 22.7353L6.44727 18.7277C8.30929 15.8491 8.30929 12.1465 6.44727 9.26782L3.85757 5.26021C3.51246 4.72515 4.13848 4.09913 4.67354 4.44424L8.68115 7.03394C11.5598 8.89596 15.2624 8.89596 18.141 7.03394Z" fill="#4865FF"/>
<path d="M26.1664 6.70028C25.7116 6.70028 25.3424 6.33109 25.3424 5.87629V4.11058C25.3424 2.94147 24.3927 1.99174 23.2236 1.99174L21.4231 1.99174C20.9683 1.99174 20.5991 1.62255 20.5991 1.16774C20.5991 0.712942 20.9683 0.34375 21.4231 0.34375L23.2236 0.34375C25.2996 0.34375 26.9904 2.03454 26.9904 4.11058V5.87629C26.9904 6.33109 26.6212 6.70028 26.1664 6.70028Z" fill="#4865FF"/>
<path d="M23.225 27.4201L21.4592 27.4201C21.0044 27.4201 20.6353 27.0509 20.6353 26.5961C20.6353 26.1413 21.0044 25.7721 21.4592 25.7721L23.225 25.7721C24.3941 25.7721 25.3438 24.8224 25.3438 23.6533V21.8528C25.3438 21.398 25.713 21.0288 26.1678 21.0288C26.6226 21.0288 26.9918 21.398 26.9918 21.8528V23.6533C26.9918 25.732 25.3037 27.4201 23.225 27.4201Z" fill="#4865FF"/>
<path d="M0.823995 6.70028C0.369192 6.70028 0 6.33109 0 5.87629V4.11058C0 2.03187 1.69079 0.34375 3.76683 0.34375L5.56731 0.34375C6.02212 0.34375 6.39131 0.712942 6.39131 1.16774C6.39131 1.62255 6.02212 1.99174 5.56731 1.99174L3.76683 1.99174C2.59772 1.99174 1.64799 2.94147 1.64799 4.11058V5.87629C1.64799 6.33109 1.2788 6.70028 0.823995 6.70028Z" fill="#4865FF"/>
<path d="M5.53254 27.4201L3.76683 27.4201C1.69079 27.4201 0 25.732 0 23.6533V21.8528C0 21.398 0.369192 21.0288 0.823995 21.0288C1.2788 21.0288 1.64799 21.398 1.64799 21.8528V23.6533C1.64799 24.8224 2.59772 25.7721 3.76683 25.7721L5.53254 25.7721C5.98734 25.7721 6.35653 26.1413 6.35653 26.5961C6.35653 27.0509 5.98734 27.4201 5.53254 27.4201Z" fill="#4865FF"/>
</svg>
</ItemIcon>
            <ItemHeader>Flexible to you</ItemHeader>
            <ItemDesc>Categorize your tasks, set up your own workflows, take notes for in-progress tasks etc etc. </ItemDesc>
         <GridImage>Plan</GridImage>
         </GridItem>
          <GridItem>
            <ItemIcon><svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2755 1.57587C18.3114 1.57587 18.4201 1.57587 18.493 1.70193C18.5659 1.828 18.511 1.92256 18.493 1.95407L13.9614 9.8334C13.3682 10.8656 13.0565 12.0396 13.0587 13.2316C13.0621 14.4102 13.3738 15.5729 13.9614 16.5939L18.5087 24.5002C18.5267 24.5317 18.5816 24.6263 18.5087 24.7523C18.4358 24.8784 18.3271 24.8784 18.2912 24.8784H1.8391C1.80322 24.8784 1.69444 24.8784 1.62155 24.7523C1.54866 24.6263 1.60361 24.5317 1.62155 24.5002L6.15313 16.6209C6.74635 15.5887 7.0581 14.4147 7.05586 13.2226C7.0525 12.0441 6.74075 10.8814 6.15313 9.86042L1.60585 1.95295C1.58791 1.92143 1.53296 1.82688 1.60585 1.70081C1.67874 1.57587 1.78752 1.57587 1.8234 1.57587H18.2755ZM18.2755 0H1.8234C0.421654 0 -0.454159 1.52296 0.246716 2.74088L4.79399 10.6472C5.25265 11.4453 5.48366 12.3357 5.4859 13.226C5.48814 14.1254 5.25713 15.0259 4.79399 15.8318L0.262415 23.7111C-0.43846 24.9291 0.437354 26.452 1.8391 26.452H18.2912C19.6929 26.452 20.5687 24.9291 19.8679 23.7111L15.3206 15.8048C14.8619 15.0067 14.6309 14.1164 14.6287 13.226C14.6264 12.3266 14.8574 11.4262 15.3206 10.6202L19.8522 2.74088C20.553 1.52296 19.6772 0 18.2755 0Z" fill="#27CBB4"/>
<path d="M8.15076 17.0086L5.59509 22.1065C5.37305 22.55 5.69378 23.0734 6.18943 23.0734H13.9641C14.4586 23.0734 14.7805 22.55 14.5584 22.1065L12.0028 17.0086C11.4006 15.8075 11.0866 14.4804 11.0866 13.1353V12.8708C11.0866 11.5977 11.4107 10.346 12.0286 9.23394L12.1553 9.00431C12.3796 8.60021 12.0891 8.10156 11.6271 8.10156H8.60269C8.14964 8.10156 7.85696 8.58333 8.06554 8.98742L8.23375 9.31385C8.78099 10.3753 9.06695 11.5527 9.06695 12.747V13.1342C9.06695 14.4804 8.75296 15.8064 8.15076 17.0086Z" fill="#27CBB4"/>
</svg>
</ItemIcon>
            <ItemHeader>Find your flow</ItemHeader>
            <ItemDesc>Hone is designed for you to focus on one task at a time, limit distractions, and hold yourself accountable.</ItemDesc>
            <GridImage>Enact</GridImage>
          </GridItem>
          <GridItem>
            <ItemIcon><svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6332 1.92519C22.3404 1.92519 23.7319 3.31408 23.7319 5.02389V22.6636C23.7319 24.3709 22.343 25.7623 20.6332 25.7623H4.68013C2.9729 25.7623 1.58144 24.3734 1.58144 22.6636V5.02389C1.58144 3.31665 2.97033 1.92519 4.68013 1.92519H20.6332ZM20.6332 0.34375H4.68013C2.09489 0.34375 0 2.43864 0 5.02389V22.6636C0 25.2489 2.09489 27.3438 4.68013 27.3438H20.6332C23.2184 27.3438 25.3133 25.2489 25.3133 22.6636V5.02389C25.3107 2.43864 23.2158 0.34375 20.6332 0.34375Z" fill="#C666F3"/>
<path d="M16.8932 20.85L14.6174 14.8051C14.4385 14.328 14.5966 13.7862 15.0059 13.4935C16.1471 12.678 16.8702 11.2983 16.7835 9.75217C16.6617 7.56144 14.883 5.80304 12.7289 5.73825C10.3576 5.66675 8.41206 7.60277 8.41206 10.0013C8.41206 11.4246 9.09789 12.6847 10.1502 13.4589C10.5606 13.7616 10.7329 14.2979 10.5518 14.7805L8.26721 20.85C8.07518 21.3616 8.44498 21.9101 8.98267 21.9101H16.1767C16.7144 21.9101 17.0853 21.3616 16.8932 20.85Z" fill="#C666F3"/>
</svg>
</ItemIcon>
            <ItemHeader>Safe and secure</ItemHeader>
            <ItemDesc>Hone's app data is stored entirely on your device and works offline.</ItemDesc>
          <GridImage>Report</GridImage>
          </GridItem>
        </ThreeGridMobile>
        </Flex>
        <FunText>© {new Date().getFullYear()}  Hone, all rights reserved</FunText>
      </MobileView>
    </>
  )
}

export default Header

const HeaderWrapper = styled.header`
  padding: 0 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 20px 0 10px 0;
  }
`
const LayoutWrapper = styled.div`
max-width: 1800px;
margin: 0 10vw;`
const ThreeGrid = styled.div`
display: grid;
padding: 5rem 0;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 4rem;
width: 100%;


`
const ThreeGridMobile = styled.div`
display: grid;
padding: 3rem 0;
grid-template-columns: 1fr;
width: 100%;


`
const GridItem = styled.div``
const GridImage = styled.div`
display: none;
width: 100%;
border-radius: 20px;
margin: 5rem 0;
padding: 1.5rem;
color:${(props) => props.theme.color.slate350}; 
font-size: 1.1em;
height: 220px;
background-color: ${(props) => props.theme.color.slate700} `
const ItemHeader = styled.div`
font-size: 1.2em;
font-weight: 500;
padding-bottom: .5rem;
 color: ${(props) => props.theme.color.slate200};
`
const ItemIcon = styled.div`padding-bottom: .75rem`
const ItemDesc = styled.div`
line-height: 1.3;
 color: ${(props) => props.theme.color.slate350}`
const Subtext = styled.h3`
  color: ${(props) => props.theme.color.gray};

  margin-bottom: 16px;
`

const FunText = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  color: ${(props) => props.theme.color.black.lightest};
  padding-bottom: 0.75rem;
  margin: 0 auto;
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  margin-bottom: 16px;
`
const CenterHead = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  padding: 40px 0 0px;
`

const HeaderTextGroup = styled.div`
  margin: 0 auto;
  text-align: left;
  align-items: left;
  flex-direction: column;
 

  h1 {
    padding-top: 0;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.large}
    color: ${(props) => props.theme.color.gray}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: block;
  padding-right: 2rem;
  align-content: left;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
    padding: 0;
    margin: 0;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
`
