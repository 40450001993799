import React from "react"
import styled from "styled-components"
import VideoPlayer from "../common/elements/videoPlayer"
import { Section, Container } from "../global"

class Features extends React.Component {
  constructor(props) {
    super(props)
    this.state = { replay: false, active: true }
    this.offerReplay = this.offerReplay.bind(this)
    this.replay = this.replay.bind(this)
  }

  offerReplay() {
    this.setState({ replay: true, active: false })
  }

  replay() {
    this.setState({ active: true, replay: false })
  }

  render() {
    return (
      // <Section id="features">
      <StyledContainer>
        {" "}
        <VideoPlayer
          src ={"https://prismic-io.s3.amazonaws.com/eric-duong/0a249cda-0f0f-477c-a3f3-ff05cc163192_Transform_Light_Final.mp4"}
          playing={this.state.active}
          offerReplay={this.offerReplay}
        ></VideoPlayer>
        {this.state.replay ? (
          <Replay onClick={this.replay}>
            <ReplayText>Replay</ReplayText>
          </Replay>
        ) : (
          <></>
        )}
      </StyledContainer>
    )
  }
}

export default Features

const StyledContainer = styled(Container)`
  padding: 0rem;
  margin-top: 2rem;
  width: 40vw;
    background-color: #F8F8FF;
  border-radius: 30px;

   @media (max-width: 880px) {
    width: 100%;
     padding: 0 !important;
     margin: 0 !important;
  }
`



const Replay = styled.button`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 0;
  text-align: center;
`

const ReplayText = styled.h4`
  color: ${(props) => props.theme.color.primary};
  position: absolute;
  padding-top: 0;
  margin-top: 0;
`