import React from "react"
import ReactPlayer from "react-player"
import styled from "@emotion/styled"
// import { isMobile } from "react-device-detect"
const NoTouch = styled("div")`
  pointer-events: none;
`

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active && this.props.active === false) {
      this.player.seekTo(0)
    }
  }
  ref = (player) => {
    this.player = player
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          padding: "0",
        }}
      >
        <ReactPlayer
          className="reactPlayer"
          url={this.props.src}
          playing={this.props.playing}
          loop={true}
          width={"90%"}
          onEnded={this.props.offerReplay}
          height={"auto"}
          ref={this.ref}
          playsinline={true}
          muted={true}
        />
      </div>
    )
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }
}

export default VideoPlayer
