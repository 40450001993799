import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
      <Fade bottom cascade>
        <FunText>© {new Date().getFullYear()}  Hone, all rights reserved</FunText>
        {/* <FooterColumn>
          <span>Features</span>
          <ul>
            <li>Automation</li>
            <li>Rewards</li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>Resources</span>
          <ul>
            <li>Compare</li>
            <li>Blog</li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>Company</span>
          <ul>
            <li>About Us</li>
            <li>Careers</li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>Social</span>
          <ul>
            <li>LinkedIn</li>
            <li>Instagram</li>
          </ul>
        </FooterColumn> */}
      </Fade>
    {/* <BrandContainer>Hone.so</BrandContainer> */}
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  margin: 0 0;
  padding: 0 0;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`

  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

const FunText = styled.p`
  text-align: right;
  font-size: 16px;
  color: ${(props) => props.theme.color.black.lightest};
  padding-bottom: 3rem;
  margin: 0 10vw;
`

export default Footer
