import React from "react"
import GradientText from "../elements/gradients/gradientText"
import GradientSvg from "../elements/gradients/gradientSvg"
import GradientButton from "../elements/gradients/gradientButton"
import styled from "styled-components"
import { graphql } from "gatsby"
import "../elements/gradients/gradient.css"
import Icon from '../../../images/icon.png'
export default () => (
      <div style={{ display: "flex", alignItems: "center", borderRadius: "10px" }}>
        <div style={{ display: "inline-flex", alignContent:'center' }}>
        <img src={Icon} style={{width:'40px'}} />
        </div>
        <div
          style={{
            paddingLeft: ".6rem",
            marginBottom: "0px",
            display: "inline-block",
            fontWeight:'600',
            fontFamily:"Inter",
            letterSpacing:'-.5px',
            fontSize:'1.1em',
            color:"#292B32"
          }}
        >
          Hone
        </div>
      </div>
)