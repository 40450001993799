import styled from "styled-components"
import React from "react"
import { Container } from "../global"

const Features = (props) => (
  <Container>
    <Grid>{props.children}</Grid>
  </Container>
)

export default Features

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  justify-content:center;
  grid-gap:3rem;
  margin: 0px auto;
  height: 90vh;
  align-items: center;

  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 5%;
  }
`
