import React from "react"
import styled from "styled-components"

import Fade from "react-reveal/Fade"
import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Fade bottom cascade>
        {/* <Subtitle>Features</Subtitle> */}
        <SectionTitle> Kick out your distractions</SectionTitle>
        <ParagraphText>
          Now more than ever distractions are stealing our attention. Did you
          know it takes 20 minutes to get back into the flow when you are
          interrupted? Hone helps you use your time in a more mindful and
          productive way, do your best work, and replace your distractions with
          focus.
        </ParagraphText>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureTitle>Flexible for You</FeatureTitle>
            <FeatureText>
              We all work in unique ways and deserve tools that work that way
              too. Stack and extend timers, and get notifications through sound
              or color with Hone.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Find your Flow</FeatureTitle>
            <FeatureText>
              Maybe you aren’t a morning person. Maybe you hit an after-lunch
              lull. No matter your style, we’ve built Hone to help you be more
              productive and focused so you can maximize deep work.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>We’re All Yours</FeatureTitle>
            <FeatureText>
              At Hone, we take data privacy seriously. How you set and manage
              your time is yours and yours alone. We'll never share your data
              without your permission.
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>
      </Fade>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;

  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 0;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  border-left: 1px solid #edeef3;
  padding: 0 3rem;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.font_size.normal};
  color: ${(props) => props.theme.color.black.lightest};
`

const ParagraphText = styled.p`
  text-align: center;
  font-size: ${(props) => props.theme.font_size.normal};
  color: ${(props) => props.theme.color.black.lightest};
  max-width: 66%;
  padding-bottom: 5rem;
  margin: 0 auto;
`
