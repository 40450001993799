import React from "react"
import './index.css'
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Grid from "../components/common/grid"
import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import FullImage from "../components/sections/full-image"
import GetStarted from "../components/sections/getstarted"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const IndexPage = () => (
  <Layout>
    
    <SEO />
    <BrowserView>
    <Navigation />
      <Header />
    <Footer />
    </BrowserView>
    <MobileView>
          <Navigation />
    <Grid>
      <Header />
    </Grid>

    </MobileView>
  </Layout>
)

export default IndexPage
